<template>
  <div class="bg-login flex justify-center items-center">
    <div
      class="bg-white bg-center mx-auto flex flex-col justify-arround items-center size-container"
    >
      <div class="flex flex-col justify-center items-center leading-none pt-20">
        <span class="logo-text">BLUEXPRESS</span>
        <span class="subtitle-logo">WEB ADMIN</span>
      </div>
      <div class="pt-10 flex flex-col justify-center">
        <vs-input
          v-model="email"
          class="input-bluexpress-component"
          placeholder="Email"
        >
        </vs-input>
        <vs-input
          type="password"
          class="input-bluexpress-component"
          v-model="password"
          placeholder="Password"
        >
        </vs-input>
      </div>
      <div class="box-action mt-2">
        <div
          class="bg-blue-600 rounded-br-lg container flex justify-center items-center cursor-pointer radius-border"
          @click="signUp"
        >
          <span class="text-box2">Log In</span>
        </div>
      </div>
      <div class="box-action2 mt-4">
        <router-link to="/"
          class="border-2 border-blue-600 rounded-md container flex justify-center items-center cursor-pointer"
        >
          <img src="../../../assets/images/casita.svg" alt="" />
          <span class="ml-2 text-box3">Go to home page</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import notification from "../../../mixins/notification";

export default {
  mixins: [notification],
  data: () => ({
    email: "",
    password: "",
  }),
  methods: {
    signUp() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });

      const user = {
        email: this.email,
        password: this.password,
      };

      this.$store.dispatch("UsersModule/signUp", user).then(() => {
        this.notification(
          "success",
          "Iniciando sesion",
          "Bienvenido a Bluexpress"
        );
        this.$router.push("/admin");
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss">
.bg-login {
  background-image: url(../../../assets/images/Login.svg);
  height: 100vh;
  background-position: center;
}

.size-container {
  height: 516px;
  width: 604px;
}

.logo-text {
  font-family: Poppins;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #0276fc;
}
.subtitle-logo {
  font-family: Poppins;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #0276fc;
}
[class*="vs-"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  margin: 2px 0px;
}

.box-action {
  width: 53%;
  height: 5vh;
  display: flex;
}

.box-action2 {
  width: 40%;
  height: 5vh;
  display: flex;
}

.text-box1 {
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #2c2c2c;
}

.text-box2 {
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.text-box3 {
  font-family: Poppins;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #0276fc;
}



.radius-border {
  border-radius: 6px;
}
</style>
