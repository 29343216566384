/* eslint-disable */
export default {
  methods: {
    notification(color, title, text) {
      this.$vs.notification({
        color,
        position: "top-right",
        title,
        text,
      });
    },
  },
};
